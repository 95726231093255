import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconEmail, IconPhone, IconDocumentQuote } from '@lmig/lmds-react';
import useThrottledHandler from '@lmig/dotcom-aspect-hooks/useThrottledHandler';
import css from 'styled-jsx/css';
import { formatPhoneNumber } from "@lmig/dotcom-aspect-comparion-helpers/phoneNumber";

const FooterBanner = ({
  nNumber,
  email,
  phoneNumber
}) => {
  const [innerWidth, setInnerWidth] = useState(1280);

  useThrottledHandler(
    'resize',
    () => {
      setInnerWidth(window.innerWidth);
    },
    { callOnInit: true },
  );

  if (innerWidth >= 768) return null;

  const { className: iconClass, styles: iconStyles } = css.resolve`
    :global(.footerBanner .lm-Icon-label){
      color: #1A1446;
    }
  `;

  return (
    <div className="footerBanner">
      <a className="footerBannerItem" rel="nofollow" href={`/quote${nNumber ? `?agent=n${nNumber}` : ''}`}>
        <IconDocumentQuote size="32" label="Get a quote" className={iconClass} />
      </a>
      {!!email &&
        <>
          <span className="spacer" />
          <a className="footerBannerItem" href={`mailto:${email}`}>
            <IconEmail size="32" label="Email" className={iconClass} />
          </a>
        </>
      }
      {!!phoneNumber &&
        <>
          <span className="spacer" />
          <a className="footerBannerItem" href={`tel:${formatPhoneNumber(phoneNumber)}`}>
            <IconPhone size="32" label="Call" className={iconClass} />
          </a>
        </>
      }
      {iconStyles}
      <style jsx>{`@import './FooterBanner.scoped.scss';`}</style>
    </div>
  );
};

FooterBanner.propTypes = {
  nNumber: PropTypes.string,
  email: PropTypes.string,
  phoneNumber: PropTypes.string,
};

export default FooterBanner;